import React from 'react'
import styled from 'styled-components'

import Layout from '../../layouts'
import MetaHeader from '../../components/MetaHeader'
import PageWrapper from '../../components/PageWrapper'

const Link = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
})``

const Page = props => {
  const email = props.location.search.split('=')[1]
  const fullLink = email
    ? `https://highlinebeta.docsend.com/view/9dypfrw?email=${email}`
    : 'https://highlinebeta.docsend.com/view/9dypfrw'
  const corporateLink = email
    ? `https://highlinebeta.docsend.com/view/v6z95ni?email=${email}`
    : 'https://highlinebeta.docsend.com/view/v6z95ni'
  const canadianLink = email
    ? `https://highlinebeta.docsend.com/view/bgats5d?email=${email}`
    : 'https://highlinebeta.docsend.com/view/bgats5d'

  return (
    <Layout>
      <MetaHeader title={'Success!'} hidden />
      <PageWrapper>
        <h2>Select your edition of the Women in Venture Report:</h2>

        <ul>
          <li>
            <Link href={fullLink}>Full Report</Link>
          </li>
          <li>
            <Link href={corporateLink}>
              Corporate Venture Capital Report only
            </Link>
          </li>
          <li>
            <Link href={canadianLink}>Canadian Report only</Link>
          </li>
        </ul>
      </PageWrapper>
    </Layout>
  )
}

export default Page
